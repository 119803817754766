<template>
  <div class="wrapper" v-scrollintoview="'Home'">
    <div
      class="corporateServices"
      :class="{ 'banner': $props.banner.length>0 }"
    >
      <div class="view-container">
        <CorporateServices />
      </div>
     </div>
    <div class="reasonForSelection">
      <div class="view-container">
        <ReasonForSelection />
      </div>
    </div>
    <div class="teamBackground">
      <div class="view-container">
        <TeamBackground />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    banner: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
    }
  },
  methods: {
  }
}
</script>

<style scoped lang="less">
.wrapper {
  .corporateServices {
    background: #E0EAFF;
    padding: 99px 0 80px 0;
  }
  .reasonForSelection {
    padding: 80px 0 62px 0;
  }
  .teamBackground {
    padding: 80px 0 229px 0;
    background: linear-gradient(270deg, #F0F3FF 6%, #C7D1FD 100%);
  }
}
@media screen and (max-width: @mobileWidth) {
  .wrapper {
    .corporateServices {
      padding: 40px 0 46px;
      &.banner {
        padding-top: 160px;
      }
    }
    .reasonForSelection {
      padding: 40px 0 25px;
    }
    .teamBackground {
      padding: 40px 0 80px 0;
      background: linear-gradient(270deg, #F0F3FF 6%, #C7D1FD 100%);
    }
  }
}
</style>
